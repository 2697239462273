import {Component, OnInit} from '@angular/core';
import {HeaderAuthorizationDetails, TokenHeaderAuthorization} from "./services/login.service";
import {ErrorDialogService, Failure} from "./common/components/dialogs/error-dialog/error-dialog.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public isRegister: boolean;

  public loginFromExternalLink : boolean;

  constructor() {
    console.log(window.location);
    if(window.location.search.toString().indexOf('?register') >= 0) {
      this.isRegister = true;
    } else if(window.location.search.toString().indexOf('?existing-user') >= 0) {
      this.loginFromExternalLink = true;
    } else {
      const oktaVersionNavigationString: string = window.localStorage.getItem('lvn');
      if (oktaVersionNavigationString) {
        window.localStorage.removeItem('lvn');
        let parsedOktaVersionNavigation = atob(oktaVersionNavigationString);
        window.location.href = `${window.location.origin}/${parsedOktaVersionNavigation}/`;
      }
    }
  }
}
